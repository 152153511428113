import React, { Component } from "react";

class Clients extends Component{
    render(){
        return(
            <React.Fragment>
  <ul className="brand-style-2">

  <li>
                    <img src="/assets/images/logos/AADITYA.jpg" alt="Logo Images"/>

                    </li>
                    
  <li>
                    <img src="/assets/images/logos/ACEMONEY.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/ARMS.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/GOLDSTUDIO.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/FARMINGTON.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/EDFOCUS.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/CROCKERY.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/MOTHERS.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/MEDADON.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/KOKKI.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/BEDSHEETSTORE.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/PUSHPAK.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/PUSHTIK.jpg" alt="Logo Images"/>

                    </li>
                    
                    <li>
                    <img src="/assets/images/logos/CEECEE.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/RAPHADON.jpg" alt="Logo Images"/>

                    </li>
        
                    <li>
                    <img src="/assets/images/logos/KESHADHARA.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/INDUS.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/GAUTHAMHOSPITAL.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/OCTAI.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/BRAMMA.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/logos/CERRA.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/BETOFLEX.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/GIF.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/HOSPITALMS.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/BROTHERS.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/SHAFEENAZ.jpg" alt="Logo Images"/>
                    </li>
                
                    <li>
                    <img src="/assets/images/logos/QSERV.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/VIMAL.jpg" alt="Logo Images"/>
                    </li>


              
                    <li>
                    <img src="/assets/images/logos/YUGEN.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/AUTOFLAME.jpg" alt="Logo Images"/>
                    </li>
                     
                    <li>
                    <img src="/assets/images/logos/COASTLINE.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/ANTHRAPER.jpg" alt="Logo Images"/>
                    </li>
                     
                    <li>
                    <img src="/assets/images/logos/ROYALBAKERS.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/SNOWEL.jpg" alt="Logo Images"/>
                    </li>
                     
                    <li>
                    <img src="/assets/images/logos/STABIL.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/logos/CREATIVEU.jpg" alt="Logo Images"/>
                    </li>
                     
                    <li>
                    <img src="/assets/images/logos/NALLARI.jpg" alt="Logo Images"/>
                    </li>
               
           
                </ul>
            </React.Fragment>
        )
    }
}
export default Clients;