import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
  <ul className="brand-style-2">
                    <li>
                    <img src="/assets/images/brand/HP-HindustanPlatinum.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/Lindstrom.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/NAVYA.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/IBRC.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/DPWORLD.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/TRANSASIA.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/ACEMONEY.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/VOLKSWAGON.jpg" alt="Logo Images"/>
                    </li>
                   
                    <li>
                    <img src="/assets/images/brand/AQUANEETA.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/JAWA.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/ARMS.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/AVOSYS.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/BIOINGREDIA.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/DRAKHILAVINOD.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/EMPOWERENGINEERS.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/DJVENTURES.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/HILLSANDHUES.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/GOLDSTUDIO.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/FARMINGTON.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/jacfurn.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/MUZIRIS.jpg" alt="Logo Images"/>

                    </li>
                    <li>
                    <img src="/assets/images/brand/TECL.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/ROYALBAKERS.jpg" alt="Logo Images"/>
                    </li>
                    <li>
                    <img src="/assets/images/brand/PERIAPEAK.jpg" alt="Logo Images"/>
                    </li>
           
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;