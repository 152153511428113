import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} || Leonart Advertising Pvt. Ltd </title>
                    <meta name="robots" content="noindex, follow" />
                    <meta name="description" content="AN ISO 9001:2015 CERTIFIED COMPANY, Advertising | Branding | Multimedia | Digital Media | TV/ Cinema Advertising | Web Design" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
