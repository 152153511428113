import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import TeamOne from "../blocks/team/TeamOne";
import SliderOne from "../component/slider/SliderOne";
import About from "../component/HomeLayout/homeOne/About";
import CounterOne from "../elements/counters/CounterOne";


import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";



const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'We Create'
    }
]

class HomePortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="active-dark bg_color--10"> 
                <Helmet pageTitle="Leonart Portfolio" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

               {/* Start Slider Area   */}
               <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1">
                    <About />
                </div>
                {/* End About Area */}


     



                    {/* Start Team Area  */}
                    <div className="rn-team-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25 mb_sm--0">
                                    <span className="subtitle">Our experts</span>
                                    <h2 className="title">Our Skilled Team</h2>
                                    <p className="description">We are young, passionate and enthusiastic creative professionals who believe in synergizing the values <br/> of our tradition with the sophistication of today’s world tools to create magnificent creative output.</p>
                                </div>
                            </div>
                        </div>
                        <TeamOne column="col-lg-3 col-md-3 col-sm-6 col-12" teamStyle="team-style--bottom" item="6" />
                    </div>
                </div>
                {/* End Team Area  */}


                                  {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Experts growts</span>
                                    <h2 className="title">Our Company Growth</h2>
                                    <p className="description">We have grown strength over the past 20 years.</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}



                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}
export default HomePortfolio;