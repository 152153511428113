import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp  } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";




const Portfolio_image = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-1.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-2.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-3.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-4.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-5.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-6.jpg" alt="React Creative Agency" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Instant Veg Upma',
        title: 'Instant Veg Upma'
    },
    {
        image: Portfolio_image2,
        category: 'Instant Veg Biriyani',
        title: 'Instant Veg Biriyani'
    },
    {
        image: Portfolio_image3,
        category: 'Sprouted Ragi Dosa',
        title: 'Sprouted Ragi Dosa'
    },
    {
        image: Portfolio_image4,
        category: 'Navadhanya Dosa',
        title: 'Navadhanya Dosa'
    },
    {
        image: Portfolio_image5,
        category: 'Kannankaya Powder',
        title: 'Kannankaya Powder'
    },
    {
        image: Portfolio_image6,
        category: 'Adai Dosa',
        title: 'Adai Dosa'
    },
]







const Portfolio_image7 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-7.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-8.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-9.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-10.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-11.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/mockups/GIF/gif-mockup-12.jpg" alt="React Creative Agency" />;

const PortfolioList1 = [
    {
        image: Portfolio_image7,
        category: 'Sprouted Ragi Cereal',
        title: 'Sprouted Ragi Cereal'
    },
    {
        image: Portfolio_image8,
        category: 'Sprouted Jowar Dosa',
        title: 'Sprouted Jowar Dosa'
    },
    {
        image: Portfolio_image9,
        category: 'Raw Banana Powder',
        title: 'Raw Banana Powder'
    },
    {
        image: Portfolio_image10,
        category: 'Sprouted Bajra Steamed PuttuPodi',
        title: 'Sprouted Bajra Steamed PuttuPodi'
    },
    {
        image: Portfolio_image11,
        category: 'Sprouted Ragi + Banana',
        title: 'Sprouted Ragi + Banana'
    },
    {
        image: Portfolio_image12,
        category: 'Sprouted Ragi Steamed PuttuPodi',
        title: 'Sprouted Ragi Steamed PuttuPodi'
    },
]


class InteriorLanding extends Component{


    stickyHeader () {}
    render(){





        return(
            <React.Fragment>
                <Helmet pageTitle="GIF Package Design" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

    
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--gif" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Great Indian Foods</h2>
                                   <p>Provides a variety of Superfood, Baby food and Ready-to-Cook recipe choices with  superior quality, authentic taste and excellent customer service.</p>
                                   <p className="theme-gradient"><Link to="/"> Home </Link> | <Link to="/package-des"> Previous </Link></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-4 col-md-4 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                 {/* Start Finding Us Area  */}
                 <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--gif" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                   
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End Finding Us Area  */}

                
                                {/* Start Portfolio Area */}
                                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList1.map((value , index) => (
                                <div className="col-lg-4 col-md-4 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </React.Fragment>
        )
    }
}

export default InteriorLanding;