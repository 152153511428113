import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import BrochureDesign from "../elements/portfolio/BrochureDesign";



import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'We Create',
        description: 'We are young, passionate and enthusiastic creative professionals who believe in synergizing the values of our tradition with the sophistication of today’s world tools to create magnificent creative output',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

class HomePortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="active-dark bg_color--10"> 
                <Helmet pageTitle="Leonart Portfolio" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}


                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--10 ptb--130">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Brochures</span>
                                    <h2 className="title">Brochure Designs</h2>
                                    <p className="description">We team LEONART paves the way to structure, design, present, establish our responsibility in the <br/>multicolored canvas of technology and expertise in the suitable and apt position and period.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <BrochureDesign item="8" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                        {/*  <div className="row">
                            <div className="col-md-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="btn-default" href="/portfolio"><span>View More Project</span></a>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                {/* End portfolio Area  */}



    


                  

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}
export default HomePortfolio;