import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp  } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";





const Portfolio_image = <img src="/assets/images/portfolio/socialposts/pp-post-1.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/socialposts/Acemoneypost-1.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/socialposts/Navyapost-1.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/socialposts/javapost-2.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/socialposts/gif-post-2.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/socialposts/vwpost-3.jpg" alt="React Creative Agency" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Peria Peak Tea'
    },
    {
        image: Portfolio_image2,
        category: 'Acemoney'
    },
    {
        image: Portfolio_image3,
        category: 'Navya Bakeshop'
    },
    {
        image: Portfolio_image4,
        category: 'Jawa Motor Bikes'
    },
    {
        image: Portfolio_image5,
        category: 'Great Indian Food'
    },
    {
        image: Portfolio_image6,
        category: 'Volkswagan'
    },
]







const Portfolio_image8 = <img src="/assets/images/portfolio/socialposts/Keshadharapost-1.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/socialposts/pp-post-2.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/socialposts/javapost-1.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/socialposts/gif-post-1.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/socialposts/Acemoneypost-2.jpg" alt="React Creative Agency" />;
const Portfolio_image13 = <img src="/assets/images/portfolio/socialposts/yogashrampost-2.jpg" alt="React Creative Agency" />;

const PortfolioList1 = [
    {
        image: Portfolio_image8,
        category: 'Keshadhara'
    },
    {
        image: Portfolio_image9,
        category: 'Peria Peak'
    },
    {
        image: Portfolio_image10,
        category: 'Jawa Post'
    },
    {
        image: Portfolio_image11,
        category: 'Great Indian Foods'
    },
    {
        image: Portfolio_image12,
        category: 'Acemoney'
    },
    {
        image: Portfolio_image13,
        category: 'Yogashram'
    },

]


class InteriorLanding extends Component{


    stickyHeader () {}
    render(){





        return(
            <React.Fragment>
                <Helmet pageTitle="Social Media Posters" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

    
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--socialmedia" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Social Media Posts</h2>
                                  <p>Engaging social media posts and content ideas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-4 col-md-4 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                 {/* Start Finding Us Area  */}
                 <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--socialmedia" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                   
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End Finding Us Area  */}

                
                {/* Start Portfolio Area */}
                    <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList1.map((value , index) => (
                                <div className="col-lg-4 col-md-4 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                 {/* Start Footer Style  */}
                 <Footer/>
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </React.Fragment>
        )
    }
}

export default InteriorLanding;