import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class ServiceDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

           {/* Start Breadcrump Area */}
           <div className="rn-page-title-area pt--60 pb--190 bg_image bg_image--video"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Clients Videos</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-4 col-12">
                                                <div className="thumb position-relative">
                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/J8X_I7b4nng" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                </div>

                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="thumb position-relative">
                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/9eN95bNDs8w?start=16" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-12">
                                                <div className="thumb position-relative">
                                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/ogH4YsMQs30?start=16" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                </div>
                                            </div>

                                        </div>
                                        {/* End Single Area */}


                                         {/* Start Single Area */}
                                            {/*<div className="row row--35 sercice-details-content align-items-center">
                                            <div className="col-lg-4 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/video-01.jpg" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='J8X_I7b4nng' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/video-02.jpg" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='9eN95bNDs8w' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>


                                        </div>*/}
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

  

              
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
          {/* Start Footer Style  */}
          <Footer />
                {/* End Footer Style  */}

            </React.Fragment>
        )
    }
}
export default ServiceDetails;