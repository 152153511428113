import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";




const Portfolio_image = <img src="/assets/images/gallery/Gallery-img-22.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/gallery/Gallery-img-7.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/gallery/Gallery-img-3.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/gallery/Gallery-img-20.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/gallery/Gallery-img-11.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/gallery/Gallery-img-10.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/gallery/Gallery-img-26.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/gallery/Gallery-img-8.jpg" alt="React Creative Agency" />;
const Portfolio_image13 = <img src="/assets/images/gallery/Gallery-img-14.jpg" alt="React Creative Agency" />;
const Portfolio_image14 = <img src="/assets/images/gallery/Gallery-img-17.jpg" alt="React Creative Agency" />;
const Portfolio_image15 = <img src="/assets/images/gallery/Gallery-img-32.jpg" alt="React Creative Agency" />;
const Portfolio_image16 = <img src="/assets/images/gallery/Gallery-img-16.jpg" alt="React Creative Agency" />;
const Portfolio_image17 = <img src="/assets/images/gallery/Gallery-img-12.jpg" alt="React Creative Agency" />;
const Portfolio_image18 = <img src="/assets/images/gallery/Gallery-img-21.jpg" alt="React Creative Agency" />;
const Portfolio_image19 = <img src="/assets/images/gallery/Gallery-img-33.jpg" alt="React Creative Agency" />;
const Portfolio_image20 = <img src="/assets/images/gallery/Gallery-img-31.jpg" alt="React Creative Agency" />;
const Portfolio_image21 = <img src="/assets/images/gallery/Gallery-img-9.jpg" alt="React Creative Agency" />;
const Portfolio_image22 = <img src="/assets/images/gallery/Gallery-img-35.jpg" alt="React Creative Agency" />;
const Portfolio_image23 = <img src="/assets/images/gallery/Gallery-img-18.jpg" alt="React Creative Agency" />;
const Portfolio_image24 = <img src="/assets/images/gallery/Gallery-img-13.jpg" alt="React Creative Agency" />;
const Portfolio_image25 = <img src="/assets/images/gallery/Gallery-img-4.jpg" alt="React Creative Agency" />;
const Portfolio_image26 = <img src="/assets/images/gallery/Gallery-img-19.jpg" alt="React Creative Agency" />;
const Portfolio_image27 = <img src="/assets/images/gallery/Gallery-img-1.jpg" alt="React Creative Agency" />;
const Portfolio_image28 = <img src="/assets/images/gallery/Gallery-img-30.jpg" alt="React Creative Agency" />;







const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Acemoney',
        title: 'Inaguration',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image2,
        category: 'Acemoney',
        title: 'Celebrations',
        description: 'Promotional and corporate T shirts for employees'
    },
    {
        image: Portfolio_image3,
        category: 'Acemoney',
        title: 'Awards',
        description: 'Professional Employee Photo ID cards with double side printed and display more info.'
    },
    {
        image: Portfolio_image8,
        category: 'Acemoney',
        title: 'Anniversary',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image9,
        category: 'Acemoney',
        title: 'Farewell',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image10,
        category: 'Acemoney',
        title: 'Snaps',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image11,
        category: 'Acemoney',
        title: '',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image12,
        category: 'Acemoney',
        title: '',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image13,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image14,
        category: 'Acemoney',
        title: '',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image15,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image16,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image17,
        category: 'Acemoney',
        title: '',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image18,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image19,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image20,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image21,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image22,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image23,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image24,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image25,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image26,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image27,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image28,
        category: 'Acemoney',
        title: '',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
 

]




class InteriorLanding extends Component{


    stickyHeader () {}
    render(){





        return(
            <React.Fragment>
                <Helmet pageTitle="Gallery" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

    
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--100 bg_color--1 bg_image bg_image--Gallery" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Photos</h2>
                                  <p>Some of our favorite moments</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--80 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-4 col-md-4 col-12 mt--30 text-center" key={index}>
                                    <div className="im_portfolio text-center">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content ">
                                            <div className="inner " >
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                    </div>
                                                     {/* <h4 className="title ">{value.title}<br/></h4> */}

                                                </div>
                                                <div className="ptb--15">
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                
  





                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </React.Fragment>
        )
    }
}

export default InteriorLanding;