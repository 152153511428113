import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiX , FiMenu } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";






const Portfolio_image = <img src="/assets/images/portfolio/mockups/acemoney/Acemoney-Mockup-1.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/mockups/acemoney/Acemoney-Mockup-2.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/mockups/acemoney/Acemoney-Mockup-3.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/mockups/acemoney/Acemoney-Mockup-4.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/mockups/acemoney/Acemoney-Mockup-5.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/mockups/acemoney/Acemoney-Mockup-6.jpg" alt="React Creative Agency" />;


const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Acemoney',
        title: 'Prepaid Card',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image2,
        category: 'Acemoney',
        title: 'Tea Cup',
        description: 'Promotional and corporate T shirts for employees'
    },
    {
        image: Portfolio_image3,
        category: 'Acemoney',
        title: 'Rupay Prepaid Card',
        description: 'Professional Employee Photo ID cards with double side printed and display more info.'
    },
    {
        image: Portfolio_image8,
        category: 'Acemoney',
        title: 'Debit & Prepaid Card',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image9,
        category: 'Acemoney',
        title: 'Prepaid Card',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image10,
        category: 'Acemoney',
        title: 'Debit Card Front & Back',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
 

]




class InteriorLanding extends Component{


    stickyHeader () {}
    render(){





        return(
            <React.Fragment>
                <Helmet pageTitle="Acemoney Mockup" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

    
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--acemoney" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Acemoney</h2>
                                  <p>AceMoney presents Kerala's first and No.1 UPI code system. Irrespective of your payment app, you scan through AceMoney and pay bills instantly.</p>
                                  <p className="theme-gradient"><Link to="/"> Home </Link> | <Link to="/package-des"> Previous </Link></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-4 col-md-4 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                 {/* Start Finding Us Area  */}
                 <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--acemoney" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                   
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End Finding Us Area  */}

                
  


        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--60 bg_color--1">
                    <Portfolio />
                </div>
                {/* End Portfolio Area */}



                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </React.Fragment>
        )
    }
}

export default InteriorLanding;