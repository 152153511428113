import React, { Component } from "react";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import ContactOne from "../elements/contact/ContactOne";


class Career extends Component{


    stickyHeader () {}
    render(){





        return(
            <React.Fragment>
                <Helmet pageTitle="Career" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

    
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--Career" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Career</h2>
                                  <p>We are constantly looking for fresh talent to join us. If you see yourself as a part of Leonart, get in touch.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                     {/* Start About Area */}
                     <div className="about-area ptb--100  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <span className="subtitle">Lets Have A Look</span>
                                            <h2 className="title">Grow With Us</h2>
                                            <p className="description">We are constantly looking for fresh talent to join us. If you see yourself as a part of Leonart, get in touch.</p>
                                        </div> 
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">Creative Designer</h5>
                                                    <p>Should able to produces artwork and design concepts that can take on many different forms</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">Web Designer</h5>
                                                    <p>Conceptualizing creative ideas with clients. · Testing and improving the design of the website.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">Digital Marketing</h5>
                                                    <p>Responsible for devising marketing campaigns to maintain a brand. Marketing professionals etc</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h5 className="title">Front Office Staff</h5>
                                                    <p>Answering all calls and directing as needed, scheduling meetings, and coordinating office activities.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

               {/* Start Portfolio Area */}
               <div className="portfolio-area pb--120 bg_color--1">
                    <ContactOne />
                </div>
                {/* End Portfolio Area */}


                
  





                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </React.Fragment>
        )
    }
}

export default Career;