import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";


import BlogContent from "../elements/blog/BlogContent";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ModalVideo from 'react-modal-video';
import { videoTagString, VideoTag } from 'react-video-tag';
import Helmet from "../component/common/Helmet";
import ProgressOne from "../blocks/progressbar/ProgressOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import BrandTwo from "../elements/BrandTwo";
import CounterOne from "../elements/counters/CounterOne";


videoTagString({ src: '/assets/images/service/video.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
    {
        textPosition: 'text-center',
        category: 'Creative Agency',
        title: 'Creative Professionals',
        description: 'We are young, passionate and enthusiastic creative professionals who believe in synergizing the values of our tradition',
        buttonText: 'Learn More',
        buttonLink: '/services'
    }
]

class HomePortfolio extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
           

            <Fragment>
                           <div className="active-dark bg_color--10"> 

                <Helmet pageTitle="Leonart Portfolio" />
        {/* Start Header Area  */}
        <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="8">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-12">
                                        <div className={`inner pt_sm--40 ${value.textPosition}`}>
                                            {value.category ? <span className="theme-color font-700">{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient mt--20">{value.title}</h1> : ''}
                                            {value.description ? <p className="font-700" style={{color:"#FD4766"}}>{value.description}</p> : ''}
                                            <a className="btn-default btn-large mt--30" href="/services"><span>{value.buttonText}</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video-background">
                                <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`}  src={`${"/assets/images/service/video.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
 
                </div>
                {/* End Slider Area   */}




        {/* Start portfolio Area  */}
        <div className="rn-portfolio-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Recent Portfolio</span>
                                    <h2 className="title">Our Portfolio</h2>
                                    <p className="description">We are young, passionate and enthusiastic creative professionals who believe in synergizing the values of our tradition with the sophistication of today’s world tools to create magnificent creative output. We are specialized in Advertising, Branding, Web Development, Digital Media Advertising.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioMasonry item="8" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                  
                    </div>
                </div>
                {/* End portfolio Area  */}

 
                

                
                <div className="service-area pb--100 bg_color--10">
          
                </div>
   

                <div className="service-area pb--120 bg_color--10">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
               {/* Start CounterUp Area */}
               <div className="rn-counterup-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Experts growts</span>
                                    <h2 className="title">Our Company Growth</h2>
                                    <p className="description">We have grown strength over the past 11 years.</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}
                
            {/* Start Brand Area */}
            <div  className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our clients</span>
                                    <h2 className="title">Brands We've Worked With</h2>
                                      {/*<p className="description">We team LEONART paves the way to structure, design, present, establish our responsibility in the <br/>multicolored canvas of technology and expertise in the suitable and apt position and period.</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

              
              
              
              
              
              
              
                {/* Start Progress Bar Area   */}
                {/*<div className="rn-progress-area pb--120 bg_color--10">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                                <div className="progress-content-here">
                                    <div className="section-title text-left">
                                        <span className="subtitle">Our Skill</span>
                                        <h2 className="title">Agency Skill</h2>
                                        <p className="description">There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.</p>
                                    </div>
                                    <ProgressOne ProgressStyle="progress-bar--3" />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>*/}
                {/* End Progress Bar Area   */}








                {/* Start Brand Area */}
               {/* <div className="rn-brand-area pb--120 bg_color--10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Top video</span>
                                    <h2 className="title">Our Clients Review</h2>
                                </div>
                            </div>
                        </div>
                        <div className="video-image-wrapper mt--30">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-10 offset-lg-1">
                                        <div className="thumb position-relative">
                                            <img className="w-100" src="/assets/images/blog/bl-big-02.jpg" alt="Service Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>   
                        </div> 
                    </div>
                </div>*/}
                {/* End Brand Area */}                



     

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </div>
            </Fragment>
        )
    }
}
export default HomePortfolio;