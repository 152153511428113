import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import { Link } from 'react-router-dom';

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const PortfolioList = [
    {
        images: '7',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '1',
        category: 'Freelancer',
        title: 'The new Thinking for Design'
    },
    {
        images: '2',
        category: 'Freelancer',
        title: 'The new Thinking for Design'
    },
    {
        images: '3',
        category: 'Freelancer',
        title: 'Getting tickets to the big show'
    },
    {
        images: '8',
        category: 'Freelancer',
        title: 'You can see your Portfolio'
    },
    {
        images: '9',
        category: 'Freelancer',
        title: 'Getting tickets to the big show'
    },
]


const TabOne = [
    {
        image: '01',
        bigImage: '/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-2.jpg',
        category: 'BrochuAnnual Transform 2023',
        title: 'Annual Transform 2023 – Future Of People And Culture Forum'
    },
    {
        image: '02',
        bigImage: '/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-3.jpg',
        category: 'Schedules and Programms',
        title: 'Schedules and Programms of Annual Transform 2023 – Future Of People And Culture'
    },
    {
        image: '03',
        bigImage: '/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-4.jpg',
        category: '6th Annual Future Fund Admin 2023',
        title: "6th Annual Future Fund Admin 2023 Online Forum"
    },
    {
        image: '04',
        bigImage: '/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-5.jpg',
        category: 'Online Forum Pricing',
        title: '6th Annual Future Fund Admin 2023 Online Forum Pricing'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-6.jpg',
        category: '6th Annual DigiGov 2023',
        title: '6th Annual DigiGov 2023 – The Future Of Government 2023 Hybrid Conference'
    },
    {
        image: '06',
        bigImage: '/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-7.jpg',
        category: 'Conference Details',
        title: 'Conference Details of 6th Annual DigiGov 2023 – The Future Of Government'
    },
]

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <div>

            <PageHelmet pageTitle='IBRC Brochure' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
  
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--ibrc" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">IBR Conferences</h2>
                                  <p>A leading edge Australian company that provides corporate executives with tailored practical conferences keeping them up-to-date with latest business trends within different industry sectors</p>
                                
                                  <p className="theme-gradient"><Link to="/"> Home </Link> | <Link to="/brochure-design"> Previous </Link></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--40 bg_color--1 line-separator">
                    <div className="container">
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-4" key={index}>
                                    {isOpen && (
                                        <Lightbox
              
                                   
                                    
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div >
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a href="#portfolio-details">
                                                            <img src={`/assets/images/portfolio/brochures/IBRC/Ibrc-brochure-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                        <p>{value.category}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}



            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}                
            </div>
        )
    }
}


export default Gallery;