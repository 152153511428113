import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import { Link } from 'react-router-dom';

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';



const TabOne = [
    {
        image: '01',
        bigImage: '/assets/images/portfolio/brochures/Amicare/amicare-brochure-1.jpg',
        category: 'Brochure',
        title: 'Work Wear Services'
    },
    {
        image: '02',
        bigImage: '/assets/images/portfolio/brochures/Amicare/amicare-brochure-2.jpg',
        category: 'Brochure',
        title: 'Sustainable Innovations'
    },
    {
        image: '03',
        bigImage: '/assets/images/portfolio/brochures/Amicare/amicare-brochure-3.jpg',
        category: 'Brochure',
        title: "India's first clean room services"
    },
    {
        image: '04',
        bigImage: '/assets/images/portfolio/brochures/Amicare/amicare-brochure-4.jpg',
        category: 'Brochure',
        title: ' Services'
    },

]

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            isOpen: false,
        };
    }
    render() {
        const { tab1, isOpen } = this.state;
        return (
            <div>

            <PageHelmet pageTitle='Amicare Brochure' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
  
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--amicare" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">AmiCare</h2>
                                  <p>Amicare Service Private Limited is one of the leading ISO certified government licensed pest control solution provider in Kerala, providing sustainable pest control solution for individuals and organizations.</p>
                                  <p className="theme-gradient"><Link to="/"> Home </Link> | <Link to="/brochure-design"> Previous </Link></p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                {/* Start Portfolio Area  */}
                <div className="rn-portfolio-area ptb--40 bg_color--1 line-separator">
                    <div className="container">
                        <div className="row">
                            {TabOne.map((value , index) => (
                                <div className="col-lg-6" key={index}>
                                    {isOpen && (
                                        <Lightbox
                                       
                                       
                                       
                                        />
                                    )}
                                    <div className="item-portfolio-static">
                                        <div >
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <a href="#portfolio-details">
                                                            <img src={`/assets/images/portfolio/brochures/Amicare/amicare-brochure-${value.image}.jpg`} alt="Portfolio Images"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="content">
                                                    <div className="inner">
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area  */}



            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}                
            </div>
        )
    }
}


export default Gallery;