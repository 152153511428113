import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-2.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/Navyabakeshop.jpg" alt="React Creative Agency" />;

const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-acemoney.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/mockups/GIF/Gif-cover-1.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/mockups/Edfocus/Edfocus-cover-1.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/mockups/mothers/mothersmockup2.jpg" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-static-07.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;




const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Metal Manufacturer',
        title: 'Hindustan Platinum',
        description: 'A leading manufacturer and refiner of precious metal products',
        locations: '/hp-mockup'

    },
    {
        image: Portfolio_image2,
        category: 'Food Products',
        title: "Navya Bakeshop",
        description: 'Manufacturing, trading, and exporting of various varieties of rice and',
        locations: '/navya-mockup'


    },
    {
        image: Portfolio_image3,
        category: 'FinTech Services',
        title: 'acemoney',
        description: "Acemoney is Kerala's First Own UPI Payment App",
        locations: '/acemoney-mockup'
    },
    {
        image: Portfolio_image4,
        category: 'Food Products',
        title: 'Great Indian Food',
        description: 'Natural, Nutritious with authentic homely taste food products',
        locations: '/gif-packages'

    },
    {
        image: Portfolio_image5,
        category: 'Edfocus',
        title: 'Edfocus Technologies',
        description: '',
        locations: '/edfocus-mockup'
    },
    {
        image: Portfolio_image6,
        category: 'Food Products',
        title: 'Mothers Food Products',
        description: 'Manufacturing, trading, and exporting of various varieties of rice and',
        locations: '/mothers-mockup'


    },
    {
        image: Portfolio_image7,
        category: 'Hoardings',
        title: 'Update Soon',
        description: 'Update Soon, Keep follow us'
    },
    {
        image: Portfolio_image8,
        category: 'Photography',
        title: 'Update Soon',
        description: 'Update Soon, Keep follow us'
    },
    {
        image: Portfolio_image9,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image10,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image11,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image12,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.locations}>
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.locations}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={value.locations}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.locations}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;