// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';






// Create Import File
import './index.scss';


// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import PackageDesign from './dark/PackageDes';
import CorporateLogo from './dark/CorporateLogo';
import BrochureDesign from './dark/BrochureDesign';
import ProfileDesign from './dark/ProfileDesign';
import WebDesign from './dark/WebDesign';
import AboutUs from './dark/AboutUs';








// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';
import HomePortfolio from './dark/HomePortfolio';

// Element Layout
import Service from "./elements/Service";
import Services from "./elements/Services";
import Career from "./elements/Career";


import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Videos from "./elements/Videos";

import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import error404 from "./elements/error404";
import Mothersmockup from "./elements/Mothersmockup";
import acemoneymockup from "./elements/acemoneymockup";
import HpMockup from "./elements/HpMockup";
import GifPackages from "./elements/GifPackages";
import Edfocusmockup from "./elements/Edfocusmockup";
import Navyamockup from "./elements/Navyamockup";
import Socialmediaposter from "./elements/Socialmediaposter";
import Otherworks from "./elements/Otherworks";



import aboutus from "./elements/aboutus";



import Lindstormbrochure from "./elements/Lindstormbrochure";
import IbrcBrochure from "./elements/IbrcBrochure";
import AmicareBrochure from "./elements/AmicareBrochure";
import AcemoneyBrochure from "./elements/AcemoneyBrochure";
import HpBrochure from "./elements/HpBrochure";

import Volkswagon from "./elements/Volkswagon";













// Blocks Layout
import Team from "./blocks/Team";
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import Columns from "./blocks/Columns";
import Button from "./blocks/Button";


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePortfolio}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/package-des`} component={PackageDesign}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-logo`} component={CorporateLogo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/brochure-design`} component={BrochureDesign}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/profile-design`} component={ProfileDesign}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/web-design`} component={WebDesign}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={AboutUs}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/aboutus`} component={aboutus}/>






                        <Route exact path={`${process.env.PUBLIC_URL}/demo`} component={Demo}/>



                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/services`} component={Services}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/career`} component={Career}/>


                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/videos`} component={Videos}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/mothers-mockup`} component={Mothersmockup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/acemoney-mockup`} component={acemoneymockup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/hp-mockup`} component={HpMockup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gif-packages`} component={GifPackages}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/edfocus-mockup`} component={Edfocusmockup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/navya-mockup`} component={Navyamockup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/Socialmediaposter`} component={Socialmediaposter}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/others`} component={Otherworks}/>





                        <Route exact path={`${process.env.PUBLIC_URL}/lindstorm-brochure`} component={Lindstormbrochure}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/ibrc-brochure`} component={IbrcBrochure}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/amicare-brochure`} component={AmicareBrochure}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/acemoney-brochure`} component={AcemoneyBrochure}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/hp-brochure`} component={HpBrochure}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/volkswagon`} component={Volkswagon}/>










                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/button`} component={Button}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/team`} component={Team}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        


                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}



ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();




