import React, { Component } from "react";
import { FiCloudLightning , FiBell, FiGlobe , FiMonitor , FiPrinter, FiCamera } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCloudLightning />,
        title: 'Creative',
        description: 'Developing a brand, deploying a bespoke marketing strategy and creative approach'
    },
    {
        icon: <FiBell />,
        title: 'Advertising',
        description: 'Creative Advertising providing services include packaging design, branding, marketing '
    },
    {
        icon: <FiGlobe />,
        title: 'Online',
        description: 'responsible for the design and development of static, dynamic or and eCommercial websites'
    },
    {
        icon: <FiMonitor />,
        title: 'Digital Marketing',
        description: 'promote products and services in social media marketing, affiliate marketing, email marketing,etc'
    },
    {
        icon: <FiPrinter />,
        title: 'Printing',
        description: ' Printing services to create professional visiting cards, Business cards and much more'
    },
    {
        icon: <FiCamera />,
        title: 'Photography',
        description: 'Elevate your business with our professional photography services...'
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services provide for you.',
        description = 'We team LEONART paves the way to structure, design, present, establish our responsibility in the multicolored canvas <br> of technology and expertise in the suitable and apt position and period',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
