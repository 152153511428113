import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";

import FooterTwo from "../component/footer/FooterTwo";


class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About Us' />


    {/* Start Header Area  */}
    <Header headerPosition="header--static logoresize" logo="all-dark" color="color-white"/>
                {/* End Header Area  */}


                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--ourcompany" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">About Our Company</h2>
                                  <p>We are young, passionate and enthusiastic creative professionals who believe in synergizing the values of our tradition with the sophistication of today’s world tools to create magnificent creative output.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Blog Details */}
                <div className="rn-blog-details pt--70 pb--10 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner" >
                                    <div className="row" style={{"border": "2px solid #fff"}}>
                                    <div className="col-lg-6">


                                       {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. </p>*/}
                                        <div className="">
                                            <img src="/assets/images/about/Gandhi-1.png" alt="Blog Images"/>
                                        </div>
                                        </div>
                                        <div className="col-lg-6" style={{"padding-top": "8%", "padding-bottom": "10%"}}>

                                        {/*<p className="mt--40">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. </p>
                                        <p>Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lo
                                        rem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
<blockquote className="rn-blog-quote"><h4>It is difficult, but not impossible, to conduct strictly honest business</h4>
<p className="">~ Mahatma Gandhi</p>
</blockquote>
</div>
</div>


                                        {/*<blockquote className="rn-blog-quote">It is difficult, but not impossible, to conduct strictly honest business<br/>~ Mahatma Gandhi</blockquote>*/}
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>*/}
                                      
  {/* <p>There are many variations of passaes of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. </p>*/}
                                        <div className="">
                                            <img src="/assets/images/about/about-21.jpg" alt="Blog Images"/>
                                            <h3 className="title">Creativity with soul</h3>

                                     <p>The band of Ad makers with a bench mark of excellence and the name is Leonart Advertising Private Limited. Advertisements that touch your heart is the vision behind this mainstream advertising factory in kochi. Intelligently adding creativity with soul, these genuine ad artists concreate your concepts with excelent quality.</p>
                                        </div>
                              

                                        <div className="">
                                            <img src="/assets/images/about/about-22.jpg" alt="Blog Images"/>
                                        </div>

                                        <h3 className="title">Look at a tiny blade of leafe,</h3>

<p>its distinct edges, sharp lines and crisp green colour. Look at it even closer and you will notice the various cells and particles that constitute its is very being. This tiny little blade of grass shows God,'s attention to the smallest detail when he created the beautiful earth. why are we telling you this? Well it is with such intent that leonart was started. Our attention to every pixel of creative output is intended to ensure that our clients recive nothing short the best when it comes to their creative and artistic requirements</p>
<p>The team leonart consits of a highly talented group of artists and designers who are ready to cater to the most articulate needs of our clients. Needles to say, its is in the team's DNA to strive and exceed the customer's expectations. So all we have to say is let us serve you with our talent and you will never have to compromise on quality</p>

                                      
                                        {/* <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-01.jpg" alt="BLog Images"/>
                                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</span>
                                            </div>
                                            
                                            <div className="content">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo .</p>
                                                <h4 className="title">Ordered & Unordered Lists.</h4>
                                                <ul className="list-style">
                                                    <li>Yet this above sewed flirted opened ouch</li>
                                                    <li>Goldfinch realistic sporadic ingenuous</li>
                                                    <li>Abominable this abidin far successfully then like piquan</li>
                                                    <li>Risus commodo viverra</li>
                                                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                </ul>
                                                <h4 className="title">Ordered & Unordered Lists.</h4>
                                                <ul className="list-style">
                                                    <li>Yet this above sewed flirted opened ouch</li>
                                                    <li>Goldfinch realistic sporadic ingenuous</li>
                                                    <li>Abominable this abidin far successfully then like piquan</li>
                                                    <li>Risus commodo viverra</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <p className="mt--25 mt_sm--5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>
                                        <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog Images"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p>
                                    */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}


          {/* Start Footer Style  */}
          <FooterTwo />
                {/* End Footer Style  */}


               
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
     

            </React.Fragment>
        )
    }
}
export default BlogDetails;