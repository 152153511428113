import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/webdesign/portfolio-farmington.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/webdesign/portfolio-Gif.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/webdesign/portfolio-tecl.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/webdesign/portfolio-dlegacy.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/webdesign/portfolio-drakhila.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/webdesign/portfolio-zalvos.jpg" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/webdesign/portfolio-stabil.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/webdesign/portfolio-nairs.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;



const PortfolioList = [
    {
        image: Portfolio_image,
        url: 'home-particles',
        category: 'www.farmingtononline.in',
        title: 'Farmington',
        description: 'Farmington is a specialist chain of retail stores that sell fruits and vegetables'
    },
    {
        image: Portfolio_image2,
        category: 'www.thegif.in',
        title: 'The Great Indian Food',
        description: 'Natural, nutritious with authentic homely taste Products'
    },
    {
        image: Portfolio_image3,
        category: 'www.teclproject.com',
        title: 'Tree Engineering Consultants',
        description: 'Leading project management consultancy, providing,'
    },
    {
        image: Portfolio_image4,
        category: 'www.dlegacyfashion.in',
        title: 'Dlegacyfashion',
        description: 'Products and apparels from multiple national and international brands'
    },
    {
        image: Portfolio_image5,
        category: 'drakhila.com',
        title: 'Yogashram',
        description: 'Naturopathy consultant and Yoga therapist, NLP PRACTITIONER'
    },
    {
        image: Portfolio_image6,
        category: 'www.zalvozinternational.com',
        title: 'Zalvoz International',
        description: 'Learning institute and offers placements abroad'
    },
    {
        image: Portfolio_image7,
        category: 'www.stabil.in',
        title: 'Stabil',
        description: 'The pioneers in manufacturing interlock/ hollow brick'
    },
    {
        image: Portfolio_image8,
        category: 'www.nairsgroup.com',
        title: "Nair's Pickle",
        description: 'Kerala style homemade pickles'
    },
    {
        image: Portfolio_image9,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image10,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image11,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image12,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                 
                                        {value.image}
                                     
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                          {value.category}
                                        </div>
                                        <h4 className="title">{value.title}</h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;