import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import Clients from "../elements/Clients";




const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'We Create'
    }
]

class HomePortfolio extends Component {
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <div className="active-dark bg_color--10"> 
                <Helmet pageTitle="Leonart Portfolio" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}


            {/* Start Brand Area */}
            <div  className="rn-brand-area bg_color--1 ptb--130">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Corporate Logo</span>
                                    <h2 className="title">Logos Created</h2>
                                      {/*<p className="description">We team LEONART paves the way to structure, design, present, establish our responsibility in the <br/>multicolored canvas of technology and expertise in the suitable and apt position and period.</p>*/}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Clients />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}



    


                  

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}
export default HomePortfolio;