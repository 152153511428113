import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


const AnyReactComponent = ({ text }) => <div>{text}</div>;


class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--100 ptb--120 bg_image bg_image--15"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Contact With Us</h2>
                                    <p>We welcome you all to our hearts to be with you in all means to the voyage of our and your, grand successful life forever.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 text-center">
                                    <span className="subtitle">Our contact address</span>
                                    <h2 className="title">Quick Contact Address</h2>
                                    <p className="description">We are completely dedicated and committed to deliver the best service to anyone who opt anything in the media scenario by closely observing their needs and wishes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:+91 9539 56 9539">+91 9539 56 9539</a></p>

                                        <p><a href="tel:+91 8921 42 7252">+91 8921 42 7252</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>Palathinkal Complex, D188,
2nd Floor North Fort Gate - 682301<br />Thripunithura, Kerala, India</p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:info@leonart.co">info@leonart.co</a></p>
                                        <p><a href="">TIMING: 9AM To 5PM</a></p>

                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

            

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '650px', width: '100%' }}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.7819749022538!2d76.3345608147938!3d9.952089792884015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b087315bc502f0f%3A0x409550b6340ddef6!2sLeonart%20advertising%20pvt.ltd!5e0!3m2!1sen!2sin!4v1674535487715!5m2!1sen!2sin" width="100%" height="650" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>



                      {/*  <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                            
                        <AnyReactComponent
                            lat={9.952930}
                            lng={76.340012}
                            text="My Marker"
                        />
                        </GoogleMapReact> */}
                    </div>
                </div>
                {/* End Contact Map  */}
                



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

               {/* Start Footer Style  */}
               <FooterTwo />
                {/* End Footer Style  */}

            </React.Fragment>
        )
    }
}
export default Contact