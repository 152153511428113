import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";


import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";




const Portfolio_image = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-4.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-5.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-6.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-8.jpg" alt="React Creative Agency" />;

const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Hindustan Platinum',
        title: 'HP Envelope Big',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image2,
        category: 'Hindustan Platinum',
        title: 'T-Shirt Mockup',
        description: 'Promotional and corporate T shirts for employees'
    },
    {
        image: Portfolio_image3,
        category: 'Hindustan Platinum',
        title: 'ID-Card',
        description: 'Professional Employee Photo ID cards with double side printed and display more info.'
    },
    {
        image: Portfolio_image4,
        category: 'Hindustan Platinum',
        title: 'ID-Card Mockup',
        description: 'Professional Employee Photo ID cards makes customers to know your employees'
    },
]







const Portfolio_image5 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-7.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-3.jpg" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-9.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-10.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/mockups/HP/HP-Mockup-11.jpg" alt="React Creative Agency" />;


const PortfolioList1 = [
    {
        image: Portfolio_image5,
        category: 'Hindustan Platinum',
        title: 'Business Card',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image6,
        category: 'Hindustan Platinum',
        title: 'Business Card Mockup',
        description: "The business card represents the company's brand and the first exposure to the overall image"
    },
    {
        image: Portfolio_image7,
        category: 'Hindustan Platinum',
        title: 'Envelope Mockup',
        description: 'Professional lightweight, simple envelopes meant for the everyday mailings'
    },
    {
        image: Portfolio_image8,
        category: 'Hindustan Platinum',
        title: 'Letter Head',
        description: ' Represents a company and can impart a first impression to its potential customers'
    },
    {
        image: Portfolio_image9,
        category: 'Hindustan Platinum',
        title: 'Tea Cup',
        description: ' Represents a company and can impart a first impression to its potential customers'
    },
]


class InteriorLanding extends Component{


    stickyHeader () {}
    render(){





        return(
            <React.Fragment>
                <Helmet pageTitle="HP Mockup" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

    
                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--3" data-black-overlay="5">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Hindustan Platinum</h2>
                                  
                                 
                                  <p>Hindustan Platinum a leading manufacturer and refiner of precious metal products creating an indelible mark for over six decades.</p>
                                 
                                  <p className="theme-gradient"><Link to="/"> Home </Link> | <Link to="/package-des"> Previous </Link></p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}


                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList.map((value , index) => (
                                <div className="col-lg-6 col-md-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">{value.title}</h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                 {/* Start Finding Us Area  */}
                 <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--3" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                   
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                {/* End Finding Us Area  */}

                
                                {/* Start Portfolio Area */}
                                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row mt_dec--30">
                            {PortfolioList1.map((value , index) => (
                                <div className="col-lg-6 col-md-6 col-12 mt--30" key={index}>
                                    <div className="im_portfolio">
                                        <div className="thumbnail_inner">
                                            <div className="thumbnail">
                                                <Link to="#">
                                                    {value.image}
                                                </Link>    
                                            </div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="portfolio_heading">
                                                    <div className="category_list">
                                                        <Link to="#">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">{value.title}</h4>
                                                </div>
                                                <div className="portfolio_hover">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                 {/* Start Footer Style  */}
                 <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </React.Fragment>
        )
    }
}

export default InteriorLanding;