import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/service/service-1.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/service/service-2.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/service/service-3.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/service/service-6.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/service/service-5.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/service/service-4.jpg" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/service/service-6.jpg" alt="React Creative Agency" />;


const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Development',
        title: 'Creative',
        description: 'Developing a brand, deploying a bespoke marketing strategy and creative approach'
    },
    {
        image: Portfolio_image2,
        category: 'Product Design',
        title: 'Advertising',
        description: 'Creative Advertising providing services include packaging design, branding, marketing'
    },
    {
        image: Portfolio_image3,
        category: 'Application',
        title: 'Online',
        description: 'responsible for the design and development of static, dynamic or and eCommercial websites'
    },
    {
        image: Portfolio_image4,
        category: 'Web Design',
        title: 'Photography',
        description: 'Promote products and services in social media marketing, affiliate marketing, email marketing,etc'
    },
    {
        image: Portfolio_image5,
        category: 'Application',
        title: 'Printing',
        description: 'Printing services to create professional visiting cards, Business cards and much more'
    },
    {
        image: Portfolio_image6,
        category: 'Photoshop',
        title: 'Digital Marketing ',
        description: 'Elevate your business with our professional photography services...'
    },
    {
        image: Portfolio_image7,
        category: 'Photoshop',
        title: 'Digital Marketing ',
        description: 'Elevate your business with our professional photography services...'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                        {value.image}
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                        </div>
                                        <h4 className="title">{value.title}</h4><br/>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;