import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import CounterOne from "../elements/counters/CounterOne";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import AboutComp from "../component/HomeLayout/homeOne/About";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";



class About extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Services' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
          

                
                {/* Start Finding Us Area  */}
                <div className="rn-page-title-area pt--80 ptb--120 bg_image bg_image--services"  data-black-overlay="4">
                <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Services</h2>
                                  <p>We are completely dedicated and committed to deliver the best service to anyone who opt anything in the media scenario by closely observing their needs and wishes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5">
                    <AboutComp />
                </div>
                {/* End About Area */}

                                  {/* Start Portfolio Area */}
                   <div className="portfolio-area ptb--100 bg_image bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--15">
                                        <span className="subtitle">Services</span>
                                        <h2 className="title">LEONART is privileged to serve you with</h2>
                                        <p className="description">We are specialized in Advertising, Branding, Web Development, Digital Media Advertising. We always provide better service to our clients.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                      
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


               {/* Start CounterUp Area */}
                <div className="rn-counterup-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Experts growts</span>
                                    <h2 className="title">Our Company Growth</h2>
                                    <p className="description">We have grown strength over the past 11 years.</p>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}


          {/* Start Portfolio Area */}
          <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}
 

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default About