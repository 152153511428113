import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/brochures/Lindstorm/Lindstorm-brochure-1.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/brochures/IBRC/IBRC-Brochure-1.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-amicare.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-acemoney.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/Volkswagon.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/Hp-brochure.jpg" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-static-07.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;



const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Textile Rental Service Company',
        title: 'Lindstrom',
        description: "One of Europe's leading textile service companies with 170 years of",
        locations: '/lindstorm-brochure'
    },
    {
        image: Portfolio_image2,
        category: 'International Bussiness Review Conference',
        title: 'IBRC Australia',
        description: 'A leading edge Australian company that provides corporate executives',
        locations: '/ibrc-brochure'

    },
    {
        image: Portfolio_image3,
        category: 'Pest Controll',
        title: 'Amicare',
        description: 'One of the leading ISO certified government licensed pest control solution provider in Kerala',
        locations: '/amicare-brochure'

    },
    {
        image: Portfolio_image4,
        category: 'Ftntech Services',
        title: 'Acemoney',
        description: "Acemoney is Kerala's own first UPI payment App ",
        locations: '/acemoney-brochure'

    },
    {
        image: Portfolio_image5,
        category: 'Vehicle manufacturer',
        title: 'Volkswagen',
        description: 'Volkswagen Passenger Cars, Volkswagen India Private Limited',
        locations: '/volkswagon'

    },
    {
        image: Portfolio_image6,
        category: 'precious metal products',
        title: 'Hindustan Platinum',
        description: 'leading manufacturer and refiner of precious metal products',
        locations: '/hp-Brochure'

    },
    {
        image: Portfolio_image7,
        category: 'Update Soon',
        title: 'Update Soon',
        description: 'Update Soon, Keep follow us',
        locations: '/'

    },
    {
        image: Portfolio_image8,
        category: 'Photography',
        title: 'Update Soon',
        description: 'Update Soon, Keep follow us',
        locations: '/lindstorm-brochure'

    },
    {
        image: Portfolio_image9,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image10,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image11,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
    {
        image: Portfolio_image12,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.'
    },
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.locations}>
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to={value.locations}>{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to={value.locations}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.locations}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;